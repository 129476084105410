<template>
  <div>
    <div class="agent-line-container">
      <span v-text="$t('agent_line')" /> :
      <div
        v-if="agentLines.length > 0"
        class="agent-line-list-container"
      >
        <span
          v-for="(item, index) in agentLines"
          :key="item.id"
        >
          <span
            class="agent-line-list"
            @click="loadAccounts(item.id)"
            v-text="item.username"
          />
          <span
            v-if="agentLines.length - 1 !== index"
            v-text="`/`"
          />
        </span>
      </div>
    </div>
    <agent-overview
      :agent="agent"
    />
    <!-- Table Top -->
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="5"
          lg="3"
        >
          <b-input-group class="mb-1-sm">
            <b-form-input
              v-model="filter.account"
              :placeholder="$t('account')"
              autocomplete="off"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                @click="loadData(1)"
                v-text="$t('search')"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col
          cols="12"
          md="7"
          lg="9"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              v-if="userData.can_edit"
              variant="primary"
              @click="createAccountModal"
            >
              {{ $t('create_account') }}
            </b-button>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.80"
    >
      <b-card
        :title="agentTitle"
        class="mb-5"
      >
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="agents"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <!-- Rows -->
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Account Status -->
            <div
              v-if="props.column.field === 'account_status'"
            >
              <b-form-checkbox
                v-model="props.row.account_status"
                class="custom-control-dark"
                name="check-button"
                :disabled="!userData.can_edit"
                switch
                @change="changeStatus($event, props.row)"
              />
            </div>
            <!-- Column: Shares -->
            <span
              v-else-if="props.column.field === 'shares'"
              v-text="`${props.row.shares}%`"
            />
            <!-- Column: Commission -->
            <span
              v-else-if="props.column.field === 'commission'"
              v-text="`${props.row.commission}%`"
            />
            <!-- Column: Account -->
            <span
              v-else-if="props.column.field === 'account'"
            >
              <a
                v-if="props.row.account_type !== 'member'"
                href="#"
                class="align-middle ml-50"
                @click="loadAccounts(props.row.id)"
              >
                <feather-icon
                  :icon="`UserIcon`"
                  style="margin-right:2px;"
                />
                <span v-text="props.row.account" />
              </a>
              <span
                v-else
                v-text="props.row.account"
              />
            </span>
            <!-- Column: Balance -->
            <span
              v-else-if="props.column.field === 'balance'"
              v-text="Number(props.row.balance).toLocaleString()"
            />
            <!-- Column: Total Balance -->
            <span
              v-else-if="props.column.field === 'total_balance'"
              v-text="Number(props.row.total_balance).toLocaleString()"
            />
            <span
              v-else-if="props.column.field === 'operation'"
            >
              <b-button
                v-if="userData.can_edit"
                variant="outline-primary"
                size="sm"
                @click="showOperation(props.row)"
                v-text="$t('operation')"
              />
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- Columns -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span v-text="$t(props.column.label)" />
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-0">
                <span class="text-nowrap">
                  <span v-text="$t('pagination_showing')" /> 1 <span v-text="$t('pagination_to')" />
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['50','100','200', '300']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> <span v-text="$t('pagination_of')" /> {{ props.total }}<span v-text="$t('pagination_entries')" /> </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-2"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
      <b-card
        :title="$t('members')"
      >
        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="members"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <!-- Rows -->
          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Account Status -->
            <div
              v-if="props.column.field === 'account_status'"
            >
              <b-form-checkbox
                v-model="props.row.account_status"
                class="custom-control-dark"
                name="check-button"
                :disabled="!userData.can_edit"
                switch
                @change="changeStatus($event, props.row)"
              />
            </div>
            <!-- Column: Shares -->
            <span
              v-else-if="props.column.field === 'shares'"
              v-text="`${props.row.shares}%`"
            />
            <!-- Column: Commission -->
            <span
              v-else-if="props.column.field === 'commission'"
              v-text="`${props.row.commission}%`"
            />
            <!-- Column: Account -->
            <span
              v-else-if="props.column.field === 'account'"
            >
              <a
                v-if="props.row.account_type !== 'member'"
                href="#"
                class="align-middle ml-50"
                @click="loadAccounts(props.row.id)"
              >
                <feather-icon
                  :icon="`UserIcon`"
                  style="margin-right:2px;"
                />
                <span v-text="props.row.account" />
              </a>
              <span
                v-else
                v-text="props.row.account"
              />
            </span>
            <!-- Column: Balance -->
            <span
              v-else-if="props.column.field === 'balance'"
              v-text="Number(props.row.balance).toLocaleString()"
            />
            <!-- Column: Total Balance -->
            <span
              v-else-if="props.column.field === 'total_balance'"
              v-text="Number(props.row.total_balance).toLocaleString()"
            />
            <span
              v-else-if="props.column.field === 'operation'"
            >
              <b-button
                v-if="userData.can_edit"
                variant="outline-primary"
                size="sm"
                @click="showOperation(props.row)"
                v-text="$t('operation')"
              />
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- Columns -->
          <template
            slot="table-column"
            slot-scope="props"
          >
            <span v-text="$t(props.column.label)" />
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-0">
                <span class="text-nowrap">
                  <span v-text="$t('pagination_showing')" /> 1 <span v-text="$t('pagination_to')" />
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['50','100','200', '300']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap "> <span v-text="$t('pagination_of')" /> {{ props.total }}<span v-text="$t('pagination_entries')" /> </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-2"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>

    <!-- Operation Modal -->
    <b-modal
      ref="operation-modal"
      :title="$t('agent_operation')"
      ok-title="Close"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-row>
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <statistic-card-vertical
            show-icon="false"
            :statistic="agent.username"
            :statistic-title="$t('agent')"
          />
        </b-col>
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <statistic-card-vertical
            show-icon="false"
            :statistic="selectedChildAgent.account"
            :statistic-title="$t('child_agent')"
          />
        </b-col>
      </b-row>
      <b-row
        class="mb-1"
      >
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="editAccount(selectedChildAgent.id)"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('modify_account')"
            />
          </b-button>
        </b-col>
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="showLogs()"
          >
            <feather-icon
              icon="MonitorIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('log')"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row
        class="mb-1"
      >
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="showTransfer(selectedChildAgent, 'transfer_to_child', $t('transfer_to_child'))"
          >
            <feather-icon
              icon="UsersIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('transfer_to_child')"
            />
          </b-button>
        </b-col>
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="showTransfer(selectedChildAgent, 'transfer_from_child', $t('transfer_from_child'))"
          >
            <feather-icon
              icon="UsersIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('transfer_from_child')"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row
        class="mb-1"
      >
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="changeTranscode()"
          >
            <feather-icon
              icon="CircleIcon"
              class="mr-50"
            />
            <span class="align-middle">
              <span
                v-if="selectedChildAgent.game_transcode === 'Hide'"
                v-text="$t('show_transcode')"
              />
              <span
                v-else
                v-text="$t('hide_transcode')"
              />
            </span>
          </b-button>
        </b-col>
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="clearSession()"
          >
            <feather-icon
              icon="CircleIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('clear_session')"
            />
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-if="(userData.role === 'admin-hide')"
        class="mb-1"
      >
        <b-col
          cols="6"
          md="6"
          xl="6"
        >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            style="width: 100%"
            @click="unban()"
          >
            <feather-icon
              icon="CircleIcon"
              class="mr-50"
            />
            <span
              class="align-middle"
              v-text="$t('unban')"
            />
          </b-button>
        </b-col>
      </b-row>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="operationHandleCancel"
              v-text="$t('close')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Transfer Modal -->
    <b-modal
      ref="transfer-modal"
      size="lg"
      :title="transferFormTitle"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="transfer-modal"
        @submit.stop.prevent="transferHandleSubmit"
      >
        <b-row>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="agent.username"
              :statistic-title="$t('agent')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="Number(agent.balance).toLocaleString()"
              :statistic-title="`${$t('amount')} (${agent.currency})`"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="`${agent.share}%`"
              :statistic-title="$t('shares')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              icon="CpuIcon"
              :statistic="`${agent.commission}%`"
              :statistic-title="$t('commission')"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="selectedChildAgent.account"
              :statistic-title="$t('child_agent')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="Number(selectedChildAgent.balance).toLocaleString()"
              :statistic-title="`${$t('amount')} (${agent.currency})`"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="`${selectedChildAgent.share}%`"
              :statistic-title="$t('shares')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              icon="CpuIcon"
              :statistic="`${selectedChildAgent.commission}%`"
              :statistic-title="$t('commission')"
            />
          </b-col>
        </b-row>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('transfer_amount')"
          label-for="transfer-input-amount"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-input
                id="transfer-input-amount"
                v-model="transferFormData.amount"
                type="text"
                autocomplete="off"
                @keyup.stop.native="changeAmount()"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
            />
          </b-row>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="3"
          :label="$t('memo')"
          label-for="transfer-input-memo"
        >
          <b-row>
            <b-col
              cols="12"
              md="12"
              xl="7"
            >
              <b-form-textarea
                id="transfer-input-memo"
                v-model="transferFormData.memo"
                rows="3"
                :maxlength="maxMemoCount"
                @keyup="getMemoRemainingCount()"
              />
            </b-col>
            <b-col
              cols="12"
              md="12"
              xl="5"
              style="text-align: left;"
            >
              <span style="line-height: 38px;"><span v-text="$t('remaining_words')" /> {{ memoRemaining }}</span>
            </b-col>
          </b-row>
        </b-form-group>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="transferHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="transferHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Create Modal -->
    <b-modal
      ref="create-account-modal"
      size="lg"
      :title="$t('create_child_agent')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="account-modal"
        @submit.stop.prevent="createAccountHandleSubmit"
      >
        <b-row>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="agent.username"
              :statistic-title="$t('agent')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="agent.currency"
              :statistic-title="$t('agent_line')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="`${agent.share}%`"
              :statistic-title="$t('shares')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              icon="CpuIcon"
              :statistic="`${agent.commission}%`"
              :statistic-title="$t('commission')"
            />
          </b-col>
        </b-row>
        <b-tabs
          v-model="accountTab"
          pills
          align="center"
        >
          <b-tab
            :title="$t('account')"
            active
          >
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('account')"
              label-for="create-account-input-account"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                >
                  <b-form-input
                    id="create-account-input-account"
                    v-model="formData.account"
                    autocomplete="off"
                    maxlength="20"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                >
                  <span
                    class="create-child-requirements"
                    style="line-height: 38px;"
                  >
                    <span v-text="$t('create_modify_user_account_field')" />
                  </span>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('password')"
              label-for="create-account-input-password"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                >
                  <b-form-input
                    id="create-account-input-password"
                    v-model="formData.password"
                    type="password"
                    autocomplete="off"
                    maxlength="20"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                >
                  <span class="create-child-requirements">
                    <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                    <span v-text="$t('create_modify_user_password_field_requirements_2')" />
                  </span>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('password_confirm')"
              label-for="create-account-input-password-confirm"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                >
                  <b-form-input
                    id="create-account-input-password-confirm"
                    v-model="formData.password_confirmation"
                    type="password"
                    autocomplete="off"
                    maxlength="20"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                >
                  <span class="create-child-requirements">
                    <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                    <span v-text="$t('create_modify_user_password_field_requirements_2')" />
                  </span>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('nickname')"
              label-for="create-account-input-nickname"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                >
                  <b-form-input
                    id="create-account-input-nickname"
                    v-model="formData.nickname"
                    :maxlength="maxNickNameCount"
                    autocomplete="off"
                    @keyup="getNickNameRemainingCount()"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                >
                  <span style="line-height: 38px;"><span v-text="$t('remaining_words')" /> {{ nickNameRemaining }}</span>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('contact_details')"
              label-for="create-account-input-country-code"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                  style="position:relative"
                >
                  <b-form-input
                    id="create-account-input-country-code"
                    v-model="formData.country_code"
                    :placeholder="$t('country_code')"
                    style="width: 130px; float:left;"
                    type="number"
                    autocomplete="off"
                  />
                  <span style="width:6px; float:left;line-height: 38px;margin-left: 2px; margin-right: 2px;">-</span>
                  <b-form-input
                    id="create-account-input-telephone-number"
                    v-model="formData.telephone_number"
                    :placeholder="$t('telephone_number')"
                    style="width: calc(100% - 140px); float:left;"
                    type="number"
                    autocomplete="off"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                />
              </b-row>
            </b-form-group>
          </b-tab>
          <b-tab
            :title="$t('agent')"
          >
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('account_type')"
              style="text-align: right"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                  style="position:relative; text-align:left;"
                >
                  <b-form-radio-group
                    v-model="formData.account_type"
                    :options="accountType"
                    name="radio-inline"
                    style="padding-top: 8px;"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                />
              </b-row>
            </b-form-group>
            <b-form-group
              v-if="(formData.account_type === 'agent' && agent.role === 'distributor') || (formData.account_type === 'agent' && (agent.role === 'agent' && agent.can_create_agent === 'Yes'))"
              label-cols="4"
              label-cols-lg="3"
              :label="$t('can_create_agent')"
              style="margin-bottom: 0px; text-align: right"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                  style="position:relative; text-align:left;"
                >
                  <b-form-radio-group
                    v-model="formData.can_create_agent"
                    :options="canCreateAgentOptions"
                    name="can-create-agent"
                    style="padding-top: 8px;"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                />
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('currency')"
              style="margin-bottom: 0px; text-align: right"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="12"
                  style="position:relative; text-align:left;"
                >
                  <p
                    style="padding: 8px 0px;"
                    v-text="formData.currency"
                  />
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('shares')"
              label-for="create-account-input-shares"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                >
                  <b-form-input
                    id="create-account-input-shares"
                    v-model="formData.share"
                    type="number"
                    autocomplete="off"
                    @focus="numberFocus('share')"
                    @blur="numberBlur('share')"
                    @keyup="validateMaxShare()"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                >
                  <span
                    class="create-child-requirements"
                    style="line-height: 38px;"
                  >
                    <span v-text="$t('limit')" /> {{ agent.share }}%
                  </span>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('commission')"
              label-for="create-account-input-commission"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                >
                  <b-form-input
                    id="create-account-input-commission"
                    v-model="formData.commission"
                    type="number"
                    autocomplete="off"
                    @focus="numberFocus('commission')"
                    @blur="numberBlur('commission')"
                    @keyup="validateMaxCommission()"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                >
                  <span
                    class="create-child-requirements"
                    style="line-height: 38px;"
                  >
                    <span v-text="$t('limit')" /> {{ agent.commission }}%
                  </span>
                </b-col>
              </b-row>
            </b-form-group>
          </b-tab>
          <b-tab
            v-if="formData.account_type !== 'company'"
            :title="$t('limit')"
          >
            <b-row class="mb-1">
              <b-col
                v-if="formData.account_type === 'member'"
                cols="12"
                md="12"
                xl="12"
              >
                <h4
                  class="text-center text-bold"
                  v-text="$t('limit_for_game')"
                />
                <h5
                  class="text-center"
                  style="font-size:11px;"
                  v-text="$t('the_game_limit_for_this_account')"
                />
                <p class="text-center">
                  (<span v-text="$t('choose_one')" />)
                </p>
                <div class="mb-0 text-center table-responsive">
                  <table class="game-limit-table">
                    <thead>
                      <tr>
                        <th style="width:10px" />
                        <th><span v-text="$t('banker')" /> / <span v-text="$t('player')" /></th>
                        <th><span v-text="$t('tie')" /></th>
                        <th><span v-text="$t('pair')" /></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in gameLimits"
                        :key="item.id"
                      >
                        <td style="width:10px">
                          <b-form-radio
                            v-model="formData.game_limit"
                            plain
                            name="game-limit"
                            :value="item.value"
                          />
                        </td>
                        <td v-text="item.banker_and_player" />
                        <td v-text="item.tie" />
                        <td v-text="item.pair" />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
              <b-col
                v-else
                cols="12"
                md="12"
                xl="12"
              >
                <h4
                  class="text-center text-bold"
                  v-text="$t('limit_for_agent')"
                />
                <h5
                  class="text-center"
                  style="font-size:11px;"
                  v-text="$t('this_limit_set_for_this_account_to_create_his_downline_limit_set')"
                />
                <p class="text-center">
                  (<span v-text="$t('can_choose_multiple')" />)
                </p>
                <div class="mb-0 text-center table-responsive">
                  <table class="game-limit-table">
                    <thead>
                      <tr>
                        <th style="width:10px" />
                        <th><span v-text="$t('banker')" /> / <span v-text="$t('player')" /></th>
                        <th><span v-text="$t('tie')" /></th>
                        <th><span v-text="$t('pair')" /></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in gameLimits"
                        :key="item.id"
                      >
                        <td style="width:10px">
                          <b-form-checkbox
                            v-model="formData.game_limits"
                            name="agent-limit"
                            :value="item.value"
                          />
                        </td>
                        <td v-text="item.banker_and_player" />
                        <td v-text="item.tie" />
                        <td v-text="item.pair" />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="createAccountHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="createAccountHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Edit Modal -->
    <b-modal
      ref="edit-account-modal"
      cancel-variant="outline-secondary"
      ok-title="Enter"
      size="lg"
      :title="$t('modify_account')"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-form
        class="account-modal"
        @submit.stop.prevent="editAccountHandleSubmit"
      >
        <b-row>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="agent.username"
              :statistic-title="$t('agent')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="agent.currency"
              :statistic-title="$t('agent_line')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              :statistic="`${agent.share}%`"
              :statistic-title="$t('shares')"
            />
          </b-col>
          <b-col
            cols="6"
            md="3"
            xl="3"
          >
            <statistic-card-vertical
              show-icon="false"
              icon="CpuIcon"
              :statistic="`${agent.commission}%`"
              :statistic-title="$t('commission')"
            />
          </b-col>
        </b-row>
        <b-tabs
          v-model="accountTab"
          pills
          align="center"
        >
          <b-tab
            :title="$t('account')"
            active
          >
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('account')"
              label-for="edit-account-input-account"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                >
                  <b-form-input
                    id="edit-account-input-account"
                    v-model="formData.account"
                    autocomplete="off"
                    maxlength="20"
                    disabled
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                >
                  <span
                    class="create-child-requirements"
                    style="line-height: 38px;"
                  >
                    <span v-text="$t('create_modify_user_account_field')" />
                  </span>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('password')"
              label-for="edit-account-input-password"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                >
                  <b-form-input
                    id="edit-account-input-password"
                    v-model="formData.password"
                    type="password"
                    autocomplete="off"
                    maxlength="20"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                >
                  <span class="create-child-requirements">
                    <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                    <span v-text="$t('create_modify_user_password_field_requirements_2')" />
                  </span>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('password_confirm')"
              label-for="edit-account-input-password-confirm"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                >
                  <b-form-input
                    id="edit-account-input-password-confirm"
                    v-model="formData.password_confirmation"
                    type="password"
                    autocomplete="off"
                    maxlength="20"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                >
                  <span class="create-child-requirements">
                    <span v-text="$t('create_modify_user_password_field_requirements_1')" /><br>
                    <span v-text="$t('create_modify_user_password_field_requirements_2')" />
                  </span>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('nickname')"
              label-for="edit-account-input-nickname"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                >
                  <b-form-input
                    id="edit-account-input-nickname"
                    v-model="formData.nickname"
                    :maxlength="maxNickNameCount"
                    autocomplete="off"
                    @keyup="getNickNameRemainingCount()"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                >
                  <span style="line-height: 38px;"><span v-text="$t('remaining_words')" /> {{ nickNameRemaining }}</span>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('contact_details')"
              label-for="edit-account-input-country-code"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                  style="position:relative"
                >
                  <b-form-input
                    id="edit-account-input-country-code"
                    v-model="formData.country_code"
                    :placeholder="$t('country_code')"
                    style="width: 130px; float:left;"
                    type="text"
                    autocomplete="off"
                  />
                  <span style="width:6px; float:left;line-height: 38px;margin-left: 2px; margin-right: 2px;">-</span>
                  <b-form-input
                    id="edit-account-input-telephone-number"
                    v-model="formData.telephone_number"
                    :placeholder="$t('telephone_number')"
                    style="width: calc(100% - 140px); float:left;"
                    type="number"
                    autocomplete="off"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                />
              </b-row>
            </b-form-group>
          </b-tab>
          <b-tab
            :title="$t('agent')"
          >
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('account_type')"
              style="text-align: right"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                  style="position:relative; text-align:left;"
                >
                  <b-form-radio-group
                    v-model="formData.account_type"
                    :options="accountType"
                    name="radio-inline"
                    style="padding-top: 8px;"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                />
              </b-row>
            </b-form-group>
            <b-form-group
              v-if="(formData.account_type === 'agent' && agent.role === 'distributor') || (formData.account_type === 'agent' && (agent.role === 'agent' && agent.can_create_agent === 'Yes'))"
              label-cols="4"
              label-cols-lg="3"
              :label="$t('can_create_agent')"
              style="margin-bottom: 0px; text-align: right"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                  style="position:relative; text-align:left;"
                >
                  <b-form-radio-group
                    v-model="formData.can_create_agent"
                    :options="canCreateAgentOptions"
                    name="can-create-agent"
                    style="padding-top: 8px;"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                />
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('currency')"
              style="margin-bottom: 0px; text-align: right"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="12"
                  style="position:relative; text-align:left;"
                >
                  <p
                    style="padding: 8px 0px;"
                    v-text="formData.currency"
                  />
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              label-for="edit-account-input-shares"
              :label="$t('shares')"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                >
                  <b-form-input
                    id="edit-account-input-shares"
                    v-model="formData.share"
                    type="number"
                    autocomplete="off"
                    @focus="numberFocus('share')"
                    @blur="numberBlur('share')"
                    @keyup="validateMaxShare()"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                >
                  <span
                    class="create-child-requirements"
                    style="line-height: 38px;"
                  >
                    <span v-text="$t('limit')" /> {{ agent.share }}%
                  </span>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label-cols="4"
              label-cols-lg="3"
              :label="$t('commission')"
              label-for="edit-account-input-commission"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                  xl="7"
                >
                  <b-form-input
                    id="edit-account-input-commission"
                    v-model="formData.commission"
                    type="number"
                    autocomplete="off"
                    @focus="numberFocus('commission')"
                    @blur="numberBlur('commission')"
                    @keyup="validateMaxCommission()"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="12"
                  xl="5"
                >
                  <span
                    class="create-child-requirements"
                    style="line-height: 38px;"
                  >
                    <span v-text="$t('limit')" /> {{ agent.commission }}%
                  </span>
                </b-col>
              </b-row>
            </b-form-group>
          </b-tab>
          <b-tab
            v-if="formData.account_type !== 'company'"
            :title="$t('limit')"
          >
            <b-row class="mb-1">
              <b-col
                v-if="formData.account_type === 'member'"
                cols="12"
                md="12"
                xl="12"
              >
                <h4
                  class="text-center text-bold"
                  v-text="$t('limit_for_game')"
                />
                <h5
                  class="text-center"
                  style="font-size:11px;"
                  v-text="$t('the_game_limit_for_this_account')"
                />
                <p class="text-center">
                  (<span v-text="$t('choose_one')" />)
                </p>
                <div class="mb-0 text-center table-responsive">
                  <table class="game-limit-table">
                    <thead>
                      <tr>
                        <th style="width:10px" />
                        <th><span v-text="$t('banker')" /> / <span v-text="$t('player')" /></th>
                        <th><span v-text="$t('tie')" /></th>
                        <th><span v-text="$t('pair')" /></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in gameLimits"
                        :key="item.id"
                      >
                        <td style="width:10px">
                          <b-form-radio
                            v-model="formData.game_limit"
                            plain
                            name="game-limit"
                            :value="item.value"
                          />
                        </td>
                        <td v-text="item.banker_and_player" />
                        <td v-text="item.tie" />
                        <td v-text="item.pair" />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
              <b-col
                v-else
                cols="12"
                md="12"
                xl="12"
              >
                <h4
                  class="text-center text-bold"
                  v-text="$t('limit_for_agent')"
                />
                <h5
                  class="text-center"
                  style="font-size:11px;"
                  v-text="$t('this_limit_set_for_this_account_to_create_his_downline_limit_set')"
                />
                <p class="text-center">
                  (<span v-text="$t('can_choose_multiple')" />)
                </p>
                <div class="mb-0 text-center table-responsive">
                  <table class="game-limit-table">
                    <thead>
                      <tr>
                        <th style="width:10px" />
                        <th><span v-text="$t('banker')" /> / <span v-text="$t('player')" /></th>
                        <th><span v-text="$t('tie')" /></th>
                        <th><span v-text="$t('pair')" /></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="item in gameLimits"
                        :key="item.id"
                      >
                        <td style="width:10px">
                          <b-form-checkbox
                            v-model="formData.game_limits"
                            name="agent-limit"
                            :value="item.value"
                          />
                        </td>
                        <td v-text="item.banker_and_player" />
                        <td v-text="item.tie" />
                        <td v-text="item.pair" />
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </b-form>
      <footer
        class="modal-footer"
        style="margin: 0px -1.4rem"
      >
        <b-row style="width: 100%">
          <b-col
            cols="12"
            md="12"
            xl="12"
            style="text-align: center;"
            class="mt-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="mr-1"
              @click="editAccountHandleCancel"
              v-text="$t('cancel')"
            />
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="editAccountHandleSubmit"
              v-text="$t('enter')"
            />
          </b-col>
        </b-row>
      </footer>
    </b-modal>

    <!-- Logs -->
    <b-modal
      ref="logs-modal"
      size="xl"
      :title="`${selectedChildAgent.account} ${$t('log')}`"
      centered
      no-close-on-backdrop
      hide-footer
    >
      <b-row class="p-1">
        <!-- Filter -->
        <b-card
          footer-tag="footer"
          class="mb-1 card-filter-container"
          style="width: 100%"
        >
          <b-row>
            <b-col
              sm="12"
              md="6"
              lg="6"
              class="card-filter-item"
            >
              <b-input-group class="mb-1">
                <b-input-group-prepend is-text>
                  <span
                    style="min-width: 130px;"
                    v-text="$t('date_from')"
                  />
                </b-input-group-prepend>
                <b-form-datepicker
                  v-model="logFilter.dateFrom"
                  :max="logFilter.dateTo"
                />
              </b-input-group>
              <b-input-group class="mb-1-sm">
                <b-input-group-prepend is-text>
                  <span
                    style="min-width: 130px;"
                    v-text="$t('time_from')"
                  />
                </b-input-group-prepend>
                <b-form-timepicker
                  v-model="logFilter.timeFrom"
                  show-seconds
                  locale="en"
                />
              </b-input-group>
            </b-col>
            <b-col
              sm="12"
              md="6"
              lg="6"
              class="card-filter-item"
            >
              <b-input-group class="mb-1">
                <b-input-group-prepend is-text>
                  <span
                    style="min-width: 130px;"
                    v-text="$t('date_to')"
                  />
                </b-input-group-prepend>
                <b-form-datepicker
                  v-model="logFilter.dateTo"
                  :min="logFilter.dateFrom"
                />
              </b-input-group>
              <b-input-group class="mb-1-sm">
                <b-input-group-prepend is-text>
                  <span
                    style="min-width: 130px;"
                    v-text="$t('time_to')"
                  />
                </b-input-group-prepend>
                <b-form-timepicker
                  v-model="logFilter.timeTo"
                  show-seconds
                  locale="en"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              sm="12"
              md="12"
              lg="12"
              class="card-filter-item mt-1"
            >
              <b-button
                variant="primary float-right"
                @click="loadLogData"
                v-text="$t('search')"
              />
            </b-col>
          </b-row>
        </b-card>
        <b-overlay
          :show="logLoading"
          opacity="0.80"
          rounded="sm"
          style="width: 100%"
        >
          <b-card>
            <!-- table -->
            <vue-good-table
              :columns="logColumns"
              :rows="logs"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <!-- Rows -->
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- Columns -->
              <template
                slot="table-column"
                slot-scope="props"
              >
                <span v-text="$t(props.column.label)" />
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-0">
                    <span class="text-nowrap">
                      <span v-text="$t('pagination_showing')" /> 1 <span v-text="$t('pagination_to')" />
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['50','100','200', '300']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap "> <span v-text="$t('pagination_of')" /> {{ props.total }}<span v-text="$t('pagination_entries')" /> </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-2"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
          <br>
        </b-overlay>
      </b-row>
    </b-modal>

  </div>
</template>

<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import StatisticCardVertical from '@/components/statistics-cards/StatisticCardVertical.vue'
import AgentOverview from '@/components/agent-overview/AgentOverview.vue'
import Ripple from 'vue-ripple-directive'

import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'

import {
  BForm,
  BRow,
  BCol,
  BButton,
  BOverlay,
  BCard,
  BPagination,
  BModal,
  BTabs,
  BTab,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormRadio,
  BFormCheckbox,
  BFormTextarea,
  BFormDatepicker,
  BFormTimepicker,
} from 'bootstrap-vue'

import Account from '@/models/Account'
import Transaction from '@/models/Transaction'
import Log from '@/models/Log'

import { EventBus } from '../../../eventbus'

export default {
  name: 'Accounts',
  components: {
    StatisticCardVertical,
    AgentOverview,
    BForm,
    BRow,
    BCol,
    BButton,
    BOverlay,
    BCard,
    BPagination,
    BModal,
    BTabs,
    BTab,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormRadio,
    BFormCheckbox,
    BFormTextarea,
    VueGoodTable,
    BFormDatepicker,
    BFormTimepicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      pageLength: 50,
      columns: [
        {
          label: 'account',
          field: 'account',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
          width: '170px',
        },
        {
          label: 'nickname',
          field: 'nickname',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'dl_amount',
          field: 'dl_amount',
          type: 'number',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'phone',
          field: 'phone',
          type: 'number',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'account_status',
          field: 'account_status',
          type: 'boolean',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'account_type',
          field: 'account_type',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'game_transcode',
          field: 'game_transcode',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'game_limit',
          field: 'game_limit',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'shares',
          field: 'shares',
          type: 'number',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'commission',
          field: 'commission',
          type: 'number',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'own_balance',
          field: 'balance',
          type: 'number',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'total_balance',
          field: 'total_balance',
          type: 'number',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'create_time',
          field: 'create_time',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'last_login',
          field: 'last_login',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
      ],
      selectedChildAgent: {
        id: null,
        account: '',
      },
      accountTab: 0,
      members: [],
      agents: [],
      loading: true,
      maxNickNameCount: 10,
      nickNameRemaining: 10,
      maxMemoCount: 180,
      memoRemaining: 180,
      formData: {
        loading: false,
        id: '',
        account: '',
        password: '',
        password_confirmation: '',
        nickname: '',
        country_code: '',
        telephone_number: '',
        account_type: '',
        share: 0,
        commission: 0,
        game_limit: null,
        game_limits: [],
        currency: null,
        parent_id: 0,
        can_create_agent: 'No',
      },
      agent: {
        id: '',
        username: '',
        currency: '',
        commission: 0,
        share: 0,
      },
      filter: {
        account: typeof this.$route.query.account !== 'undefined' ? this.$route.query.account : '',
        agent: typeof this.$route.query.agent !== 'undefined' ? this.$route.query.agent : '',
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
      },
      agentTitle: '',
      agentLines: [],
      accountType: [],
      canCreateAgentOptions: [{
        text: 'Yes',
        value: 'Yes',
      }, {
        text: 'No',
        value: 'No',
      }],
      gameLimits: [],
      transferFormTitle: '',
      transferFormData: {
        loading: false,
        transfer_from: '',
        transfer_to: '',
        amount: '',
        max_amount: 0,
        memo: '',
        transaction_type: '',
      },
      logFilter: {
        dateFrom: `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 518400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 518400000).getDate())}`,
        dateTo: `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
        timeFrom: '00:00:00',
        timeTo: '23:59:59',
        operatorAccount: '',
      },
      logLoading: false,
      logColumns: [
        {
          label: 'operator_account',
          field: 'operator_account',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'type',
          field: 'type',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'related_account',
          field: 'related_account',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'time',
          field: 'time',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'ip',
          field: 'ip',
          type: 'text',
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
        {
          label: 'memo',
          field: 'memo',
          sortable: false,
          filterOptions: {
            enabled: false,
            placeholder: 'Search',
          },
        },
      ],
      logs: [],
    }
  },
  mounted() {
    this.loadData()
    if (this.userData.can_edit) {
      this.columns.push({
        label: 'operation',
        field: 'operation',
        sortable: false,
        filterOptions: {
          enabled: false,
          placeholder: 'Search',
        },
      })
    }
  },
  methods: {
    changeAmount() {
      let result = 0
      let balance = 0

      if (this.transferFormData.transaction_type === 'transfer_to_child') {
        balance = this.agent.balance
      } else {
        balance = this.selectedChildAgent.balance
      }
      if (Number(String(this.transferFormData.amount).replace(/\D/g, '')) > balance) {
        result = String(balance).replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace('.00', '')
      } else {
        result = String(this.transferFormData.amount).replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      this.transferFormData.amount = result
    },
    loadAccounts(id) {
      this.filter.agent = id
      this.filter.account = ''
      this.loadData(1)
    },
    async loadData() {
      this.loading = true
      const searchParams = {}
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })
      this.$router.replace({ query: searchParams })
      const response = await Account.accountList(searchParams)
      this.members = response.data.members
      this.agents = response.data.agents
      this.agent = response.data.meta.agent
      this.accountType = response.data.meta.options.account_types
      this.gameLimits = response.data.meta.options.game_limits
      this.agentLines = response.data.meta.agent_lines

      if (this.agent.role === 'admin') {
        this.agentTitle = this.$t('companies')
      } else if (this.agent.role === 'company') {
        this.agentTitle = this.$t('distributors')
      } else if (this.agent.role === 'distributor') {
        this.agentTitle = this.$t('agents')
      } else if (this.agent.role === 'agent') {
        this.agentTitle = this.$t('agents')
      }

      EventBus.$emit('getMyUpdatedUserData') // Todo: Need to refactor
      this.loading = false
    },
    async changeStatus(status, data) {
      await Account.changeStatus({ id: data.id, status })
    },
    resetFormData() {
      this.formData = {
        loading: false,
        id: '',
        account: '',
        password: '',
        password_confirmation: '',
        nickname: '',
        country_code: '',
        telephone_number: '',
        account_type: null,
        share: 0,
        commission: 0,
        game_limit: null,
        game_limits: [],
        currency: this.agent.currency,
        parent_id: this.agent.id,
        can_create_agent: 'No',
      }
      if (this.accountType.length > 0) {
        this.formData.account_type = this.accountType[0].value
      }
    },
    createAccountModal() {
      this.resetFormData()
      this.$refs['create-account-modal'].show()
    },
    createAccountHandleCancel() {
      this.$refs['create-account-modal'].hide()
    },
    async createAccountHandleSubmit() {
      try {
        if (this.formData.loading === false) {
          this.formData.loading = true
          const response = await Account.store(this.formData)
          this.formData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            this.filter.account = ''
            await this.loadData(1)
            this.$refs['create-account-modal'].hide()
            this.resetFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.tab !== 'undefined') {
              this.accountTab = response.data.results.tab
            }
            if (typeof response.data.results.field_id !== 'undefined') {
              document.getElementById(response.data.results.field_id).focus()
            }
          }
        }
      } catch (e) {
        this.formData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    async showOperation(data) {
      this.selectedChildAgent = data
      this.$refs['operation-modal'].show()
    },
    operationHandleCancel() {
      this.$refs['operation-modal'].hide()
    },
    async editAccount(id) {
      this.resetFormData()
      const response = await Account.edit({ id })
      this.formData = response.data
      this.formData.loading = false
      this.getNickNameRemainingCount()
      this.$refs['edit-account-modal'].show()
    },
    editAccountHandleCancel() {
      this.$refs['edit-account-modal'].hide()
    },
    async editAccountHandleSubmit() {
      try {
        if (this.formData.loading === false) {
          this.formData.loading = true
          const response = await Account.update(this.formData)
          this.formData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            await this.loadData()
            this.selectedChildAgent.account = response.data.results.custom.account.username
            this.selectedChildAgent.balance = response.data.results.custom.account.balance
            this.selectedChildAgent.share = response.data.results.custom.account.share
            this.selectedChildAgent.commission = response.data.results.custom.account.commission
            this.$refs['edit-account-modal'].hide()
            this.resetFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.tab !== 'undefined') {
              this.accountTab = response.data.results.tab
            }
            if (typeof response.data.results.field_id !== 'undefined') {
              document.getElementById(response.data.results.field_id).focus()
            }
          }
        }
      } catch (e) {
        this.formData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    showTransfer(data, transactionType, title) {
      this.resetTransferFormData()
      this.transferFormTitle = title
      this.transferFormData.transaction_type = transactionType
      if (transactionType === 'transfer_to_child') {
        this.transferFormData.max_amount = this.agent.balance
        this.transferFormData.transfer_from = this.agent.id
        this.transferFormData.transfer_to = data.id
        this.max_amount = this.agent.balance
      } else {
        this.transferFormData.max_amount = data.balance
        this.transferFormData.transfer_from = data.id
        this.transferFormData.transfer_to = this.agent.id
        this.max_amount = data.balance
      }
      this.selectedChildAgent = data
      this.$refs['transfer-modal'].show()
    },
    transferHandleCancel() {
      this.$refs['transfer-modal'].hide()
    },
    resetTransferFormData() {
      this.transferFormData = {
        loading: false,
        transfer_from: '',
        transfer_to: '',
        amount: '',
        memo: '',
        transaction_type: '',
      }
    },
    async transferHandleSubmit() {
      try {
        if (this.transferFormData.loading === false) {
          this.transferFormData.loading = true
          const response = await Transaction.transfer(this.transferFormData)
          this.transferFormData.loading = false
          if (response.data.status) {
            this.$swal({
              title: 'Success!',
              text: response.data.results.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            await this.loadData()
            this.$refs['transfer-modal'].hide()
            this.selectedChildAgent.balance = response.data.results.custom.transaction.balance
            this.resetTransferFormData()
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.results.message,
              },
            }, {
              position: 'top-center',
            })
            if (typeof response.data.results.field_id !== 'undefined') {
              document.getElementById(response.data.results.field_id).focus()
            }
          }
        }
      } catch (e) {
        this.transferFormData.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    async changeTranscode() {
      try {
        const response = await Account.changeTranscode(this.selectedChildAgent)
        if (response.data.status) {
          this.$swal({
            title: 'Success!',
            text: response.data.results.message,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
          await this.loadData()
          this.selectedChildAgent.game_transcode = this.results
          if (typeof response.data.results.custom.transcode !== 'undefined') {
            this.selectedChildAgent.game_transcode = response.data.results.custom.transcode
          }
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.results.message,
            },
          }, {
            position: 'top-center',
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    async clearSession() {
      try {
        const response = await Account.clearSession(this.selectedChildAgent)
        if (response.data.status) {
          this.$swal({
            title: 'Success!',
            text: response.data.results.message,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.results.message,
            },
          }, {
            position: 'top-center',
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    async unban() {
      try {
        const response = await Account.unban(this.selectedChildAgent)
        if (response.data.status) {
          this.$swal({
            title: 'Success!',
            text: response.data.results.message,
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error',
              icon: 'CoffeeIcon',
              variant: 'danger',
              text: response.data.results.message,
            },
          }, {
            position: 'top-center',
          })
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: e.message,
          },
        }, {
          position: 'top-center',
        })
      }
    },
    getNickNameRemainingCount() {
      if (this.formData.nickname) {
        this.nickNameRemaining = this.maxNickNameCount - this.formData.nickname.length
      } else {
        this.nickNameRemaining = this.maxNickNameCount
      }
    },
    getMemoRemainingCount() {
      if (this.transferFormData.memo) {
        this.memoRemaining = this.maxMemoCount - this.transferFormData.memo.length
      } else {
        this.memoRemaining = this.maxMemoCount
      }
    },
    validateMaxCommission() {
      if (this.formData.commission > this.agent.commission) {
        this.formData.commission = this.agent.commission
      }
      if (this.formData.commission < 0 || this.formData.commission.length === 0) {
        this.formData.commission = ''
      }
    },
    numberFocus(data) {
      if (data === 'share') {
        if (this.formData.share === '0' || this.formData.share === 0) {
          this.formData.share = ''
        }
      }
      if (data === 'commission') {
        if (this.formData.commission === '0' || this.formData.commission === 0) {
          this.formData.commission = ''
        }
      }
    },
    numberBlur(data) {
      if (data === 'share') {
        if (this.formData.share < 0 || this.formData.share.length === 0) {
          this.formData.share = 0
        }
      }
      if (data === 'commission') {
        if (this.formData.commission < 0 || this.formData.commission.length === 0) {
          this.formData.commission = 0
        }
      }
    },
    validateMaxShare() {
      if (this.formData.share > this.agent.share) {
        this.formData.share = this.agent.share
      }
      if (this.formData.share < 0 || this.formData.share.length === 0) {
        this.formData.share = ''
      }
    },
    showLogs() {
      this.$refs['logs-modal'].show()
      this.logFilter.relatedAccount = this.selectedChildAgent.account
      this.logFilter.dateFrom = `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 518400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 518400000).getDate())}`
      this.logFilter.dateTo = `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`
      this.logFilter.timeFrom = '00:00:00'
      this.logFilter.timeTo = '23:59:59'
      this.loadLogData()
    },
    async loadLogData() {
      this.logLoading = true
      const searchParams = {}
      Object.keys(this.logFilter).forEach(e => {
        if (typeof this.logFilter[e] === 'number') {
          if (this.logFilter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        }
        if (typeof this.logFilter[e] === 'object') {
          if (this.logFilter[e].length > 0) {
            let newData = ''
            Object.keys(this.logFilter[e]).forEach(d => {
              newData += `${this.logFilter[e][d].value},`
            })
            searchParams[e] = newData
          }
        } else if (typeof this.logFilter[e] === 'string') {
          if (this.logFilter[e] !== null && this.logFilter[e].length > 0) {
            searchParams[e] = this.logFilter[e]
          }
        }
      })
      const response = await Log.list(searchParams)
      this.logs = response.data.logs
      this.logLoading = false
    },
  },
}
</script>

<style>

</style>
